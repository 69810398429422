export default {
  'color-mode-toggle': {
    display: 'inline-flex',
    borderRadius: 0,
    paddingY: 2,
    paddingX: [2, 3],
    backgroundColor: 'transparent',
    color: 'text',
    appearance: 'none',
  },
}
